import * as React from 'react'
import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Icon from '@/@core/components/icon'
import TabContent from '@/custom-components/tab-section/TabContent'

const StyledAccordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  })
)

const AccordionSummary = styled(props => (
  <MuiAccordionSummary expandIcon={<Icon icon={'iconamoon:arrow-down-2'} width={26} height={26} />} {...props} />
))(({ theme }) => ({
  borderRadius: '6.86px',
  background: '#FFF',
  boxShadow: '0px 1.9054380655288696px 6.0974016189575195px 0px rgba(8, 15, 52, 0.06)',

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)'
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    color: '#1678FB',
    fontWeight: 600,
    marginLeft: theme.spacing(1)
  },

  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    color: '#000E21',
    fontSize: '18px',

    lineHeight: '1.625rem',
    letterSpacing: '0.0015rem',
    fontWeight: 400
  }
}))

export default function AccordionForSmallDevices({ data, defaultExpanded = 0, tabClassNames }) {
  const [expanded, setExpanded] = React.useState(defaultExpanded)

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <>
      {data.length > 0 &&
        data.map((eachPanel, panelIndex) => {
          return (
            <StyledAccordion
              sx={{
                '.MuiAccordionSummary-root.Mui-expanded': { borderBottom: '1px solid #A796F2' }
              }}
              key={panelIndex}
              expanded={expanded === panelIndex}
              onChange={handleChange(panelIndex)}
            >
              <AccordionSummary aria-controls='payment-management' id='payment-management'>
                {eachPanel?.label?.html ? eachPanel?.label?.html : eachPanel?.label}
              </AccordionSummary>

              <TabContent key={panelIndex} data={eachPanel} tabClassNames={tabClassNames} />
            </StyledAccordion>
          )
        })}
    </>
  )
}
