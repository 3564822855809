import Image from 'next/image'
import { cn } from '@/lib/utils'
import { TabContent } from './constants'
import CallToActionButton from '@/v1/@core/CallToActionButton'
import { H3, RenderParsedContent } from '@/v1/@core/typography/Typography'
import { TabClassNames } from '@/custom-components/tab-section/TabSectionGenerate'

interface TabContentProps {
  data: TabContent
  tabClassNames?: TabClassNames
}

const TabContent = ({ data, tabClassNames }: TabContentProps) => {
  return (
    <>
      <div className={cn('mt-6', tabClassNames?.parent_content_wrapper)}>
        {!!data?.title?.html && (
          <H3 className={cn('mb-2 px-5 lg:mb-4 lg:px-0', data?.title?.classes)}>{data?.title?.html}</H3>
        )}
        {!!data?.content?.html && (
          <div className={cn('mb-2 px-5 text-twilight body-content lg:mb-4 lg:px-0', data?.content?.classes)}>
            {data?.content?.html}
          </div>
        )}

        <div className={cn('grid grid-cols-1 gap-2 lg:grid-cols-2', tabClassNames?.content_wrapper)}>
          <div
            className={cn(
              'order-2 col-span-2 px-2 pb-0 lg:order-1 lg:col-span-1 lg:p-4 lg:px-0 lg:pb-4',
              tabClassNames?.content
            )}
          >
            {!!data?.detail_content?.html && (
              <div className={cn('mb-2 text-twilight body-content', data.detail_content.classes)}>
                {data?.detail_content?.html}
              </div>
            )}
            {!!data?.detail?.length && (
              <div className={'space-y-6'}>
                {data.detail.map((item, index) => {
                  return (
                    <>
                      <div key={index}>
                        <div className='flex items-center gap-1'>
                          {!!item?.icon && <div className='h-8 w-8'> {item?.icon} </div>}
                          {!!item?.title?.html && (
                            <H3 className={cn('mb-1 sm:mb-2', item?.title?.classes)}>{item?.title?.html}</H3>
                          )}
                        </div>
                        {!!item?.content && <RenderParsedContent>{item?.content}</RenderParsedContent>}
                        {!!item?.button && (
                          <CallToActionButton
                            className='mt-6'
                            text={item?.button?.text}
                            href={item?.button?.href}
                            icon
                          />
                        )}
                      </div>
                    </>
                  )
                })}
              </div>
            )}
          </div>
          <div
            className={cn(
              'order-1 col-span-2 px-2 pb-4 lg:order-2 lg:col-span-1 lg:p-4 lg:px-0 lg:pb-4',
              tabClassNames?.image_wrapper
            )}
          >
            <Image src={data?.img} alt={data?.altTxt ?? ''} className={cn('mx-auto', tabClassNames?.image)} />
          </div>
        </div>
      </div>
    </>
  )
}

export default TabContent
